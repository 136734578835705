import { selectItemSchema } from '@integration-layer/schemas/GAEvents/selectItem'

import snakeCase from 'lodash/snakeCase'

type Product = {
  sku: string
  name: string
  brand: string
  color: string
  price: number
  quantity?: number
  available: boolean
  img: string
  discount: number
  coupon?: string
}

export const useGASelectItem = () => {
  const appConfig = useAppConfig()
  const algoliaIndex = appConfig.currentAlgoliaIndex
  const { getCurrencySymbolFromCountryCode } = useCurrency()
  const { country } = useRouteHelper()

  const dispatchSelectItemEvent = async (product: Product) => {
    try {
      const algoliaObject = await $fetch(
        `/api/getProduct/${algoliaIndex}/${product.sku}`
      )

      const currency = getCurrencySymbolFromCountryCode(country)

      const { category1, category2, category4 } = extractCategories(
        algoliaObject.hierarchicalCategories
      )

      useGASendEvent(
        selectItemSchema,
        {
          event: 'select_item',
          eventID: '004',
          ecommerce: {
            currency: currency?.code ?? 'EUR',
            item_list_id: '',
            item_list_name: snakeCase(`${algoliaObject.Brand}-${category2}`),
            items: [
              {
                item_id: product.sku,
                item_name: snakeCase(product.name),
                affiliation: 'armani.com',
                coupon: product?.coupon ?? '',
                discount: product.discount,
                index: 1,
                item_brand:
                  product.brand.toLowerCase() === 'ea7'
                    ? 'EA7'
                    : snakeCase(product.brand ?? 'armani group'),
                item_category: snakeCase(category1),
                item_category2: snakeCase(category2),
                item_category4: snakeCase(category4),
                item_list_id: '',
                item_list_name: snakeCase(
                  `${algoliaObject.Brand}-${category2}`
                ),
                item_variant: product.color,
                price: product.price,
                quantity: product.quantity ?? 1,
                item_availability: product.available ? 1 : 0,
                item_size: '',
                item_reservable: 0,
                item_discount: product.discount ? 1 : 0,
                item_mfc_id: algoliaObject.MFC,
                item_img: product.img,
              },
            ],
          },
        },
        {
          clearEcommerce: true,
        }
      )
    } catch {
      console.error('product not found')
    }
  }
  return { dispatchSelectItemEvent }
}

import { z } from 'zod'
import { itemSchema } from '.'

export const selectItemSchema = z.object({
  event: z.literal('select_item'),
  eventID: z.literal('004'),
  ecommerce: z.object({
    currency: z.string(),
    item_list_id: z.string(),
    item_list_name: z.string(),
    items: z.array(itemSchema),
  }),
})
